import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import './App.css';
const ScanResultPage = () => {
  const { productId } = useParams();
  const [productInfo, setProductInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
 // const location = useLocation();
  console.log(productId);
  React.useEffect(() => {
   /*  const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id'); 
    console.log(id); */
    // Call the ExpressJS API to verify the product using fetch
    const fetchProductInfo = async () => {
      try {
        let locationData = {};
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              locationData = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              };
            },
            (error) => console.error('Error getting location:', error)
          );
        }
        // Get device information
        const deviceInfo = navigator.userAgent;
      //  const response = await fetch(`http://localhost:8080/crud/posts/${productId}`
      const response = await fetch(`https://apiarmedo.protto.es/crud/posts/${productId}` , {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            productId,
            location: locationData,
            device: deviceInfo
          })
        });
        const data = await response.json();
        console.log(data);
        setProductInfo(data);
      } catch (err) {
        setError('Failed to fetch product info');
      } finally {
        setLoading(false);
      }
    };
    fetchProductInfo();
  }, [productId]);
  if (loading) return <p>Cargando...</p>;
  if (error) return <p>{error}</p>;
  return (
    <>
{/*     <div>
      <h1>Product Verification</h1>
      {productInfo ? (
        <div>
          <p><strong>Product Name:</strong> {productInfo.name}</p>
          <p><strong>Price:</strong> ${productInfo.price}</p>
          <p><strong>Verified:</strong> {productInfo.verified ? 'Yes' : 'No'}</p>
        </div>
      ) : (
        <p>Product not found.</p>
      )}
    </div> */}
    <div className="container">
    <header className="header">
    {productInfo.image_name && <img src={`https://apiarmedo.protto.es/file/show/${productInfo.image_name}`}	 alt="Product Image" className="product-image" />}
        <h1 className="product-name">{productInfo.title}</h1>
        {/* <p className="verification-status">Authentico</p> */}<img style={{marginTop: '20px'}} src='/verified.png' alt="verified" className="verified-image" />
    </header>
    <section className="product-details">
        <div className="detail">
        {productInfo.modelName && <p><strong>Modelo:</strong> {productInfo.modelName}</p>}
        {productInfo.serialNumber && <p><strong>Num. serie:</strong> {productInfo.serialNumber}</p>}
        {productInfo.manufactureDate && <p><strong>Manufacture Date:</strong> {productInfo.manufactureDate}</p>}
        </div>
        <div className="verification-code">
        {productInfo.description && <p><strong>Descripcion:</strong> {productInfo.description}</p>}
            <p><strong>Ultimo Escaneo:</strong>{productInfo.lastScanned}</p>
        </div>
    </section>
    <footer className="footer">
        {/* <img src="https://via.placeholder.com/100x50" alt="Brand Logo" className="brand-logo" /> */}
        {productInfo.distributor && <p><strong>Distribuidor:</strong> {productInfo.distributor}</p>}
        <div><a href="mailto:info@protto.es" className="contact-support">Contactar con Soporte</a></div>
        <div><a href="mailto:info@protto.es" className="report-robo">Reportar Robo</a></div>
    </footer>
</div></>
  );
};
function App() {
  return (
    <Router>
       <Routes>
      <Route path="/verify/:productId" element={<ScanResultPage />} />
      </Routes>
    </Router>
  );
}
export default App;